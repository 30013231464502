export default function ErrorMessage({
  error,
  className,
  ...props
}: {
  error: any;
  [x: string]: any;
}) {
  const message =
    typeof error === "string"
      ? error
      : error?.message ?? "There was an unexpected error, please try again later";
  const classNames = ["bg-red-100 p-3 rounded text-left text-red-500", className].join(" ");

  return (
    error && (
      <div className={classNames} {...props}>
        {message}
      </div>
    )
  );
}
